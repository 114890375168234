<template>
  <b-modal
    id="selectTreasury"
    size="sm"
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
    no-close-on-backdrop
  >
    <template #modal-header="">
      <div slot="modal-title" class="modal-title">
        <b-icon icon="cash"></b-icon>
        <span>Casse</span>
      </div>
    </template>

    <template #modal-footer="{ cancel }">
      <b-form-group>
        <b-btn
          @click="
            $emit('treasury');
            cancel();
          "
          size="sm"
          variant="outline-secondary"
          >ANNULLA</b-btn
        >
      </b-form-group>
    </template>
    <b-row>
      <div class="col-md-12 mb-3">
        Per procedere con il salvataggio, è necessario selezionare una cassa che
        verrà utilizzata per ciascun insoluto.
      </div>
    </b-row>
    <b-row>
      <div class="col-md-12">
        <base-select
          name="status_payment"
          label="Seleziona una cassa"
          :options="treasury_options"
          @select="onSelectTreasury"
        />
      </div>
    </b-row>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import BaseSelect from "@/components/form/BaseSelect";
export default {
  data() {
    return {
      treasury_options: [],
    };
  },
  components: {
    BaseSelect,
  },
  props: {
    opts: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {},
  // mounted() {
  //   this.$refs['myRelation'].firstChild.addEventListener('click', function(event) {
  //     event.preventDefault();
  //     console.log('clicked: ', event.target);
  //   })
  // },
  beforeMount() {
    // se non sono passate come prop, le recupera dai dati di login
    // NOTA: la prop contiene dai 'freschi', NON sono dati di login
    this.treasury_options = this.opts.length
      ? this.opts
      : this.getAllTreasuries().filter(
          (e) =>
            e.sector.is_abstract.value === "N" &&
            e.sector.is_system.value === "N" &&
            e.sector.is_agency.value === "N"
        );
  },
  methods: {
    onSelectTreasury(selected) {
      console.debug("onSelectTreasury: ", selected);
      this.$emit("treasury", selected);
      this.$bvModal.hide("selectTreasury");
    },
    ...mapGetters("auth", {
      getAllTreasuries: "allTreasuries",
    }),
  },
};
</script>
<style lang="scss" scoped></style>
